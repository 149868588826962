import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      width: 400,
    },
  }),

  button: (theme) => ({
    display: "flex",
    margin: "0 auto",

    [theme.mediaRequests.mobile]: {
      width: "100%",
    },
  }),
});
