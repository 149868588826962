import React, { Fragment, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Dialog from "@ui-kit/Dialog";
import Button from "@ui-kit/Button";
import Typography from "@ui-kit/Typography";
import { useCss } from "./styles";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { Spacer } from "@ui-kit/Spacer";

const ConnectParentDialog: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ facility }] = useDeepDivePanelStore();
  const css = useCss();

  const close = useCallback(() => {
    history.replace({
      pathname: `/map/${facility.id}`,
      search,
    });
  }, [history, search]);

  return (
    <Dialog
      open
      onClose={close}
      title="Connect with families"
      tier={facility.subscriptionTier}
    >
      <div css={css.root}>
        {!!facility.accountId ? (
          <Fragment>
            <Typography gap bolded>
              The connect with families feature is available exclusively to Ivy
              Tier Schools.
            </Typography>

            <Typography paragraph align="center" variant="h5">
              About school tiers
            </Typography>

            <Typography paragraph>
              In addition to exclusive access to our suite of lead generation
              tools, Ivy Tier Schools also receive our premier school profile
              upgrade.
            </Typography>

            <Typography gap>
              To learn more about school tiers, contact us at{" "}
              <a href="mailto:support@parentdayout.com" className="g-link">
                support@parentdayout.com
              </a>
            </Typography>
          </Fragment>
        ) : (
          <Fragment>
            <Typography gap align="center" bolded>
              The connect with families feature is
              <br />
              available to school administrators.
            </Typography>

            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              css={css.button}
              link={`/map/${facility.id}/claim`}
              name="goToClaim"
            >
              I am the school administrator
            </Button>

            <Spacer size="medium" />

            <Typography paragraph align="center" variant="h5">
              About lead generation tools
            </Typography>

            <Typography>
              Certain lead generation tools are available exclusively to our Ivy
              Tier schools. If you experience connectivity issues after claiming
              your school, please contact our support team regarding your tier
              upgrade.
            </Typography>
          </Fragment>
        )}
      </div>
    </Dialog>
  );
};

export default ConnectParentDialog;
